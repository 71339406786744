import axios from "axios";

const SHEET_ID = import.meta.env.VITE_SHEET_ID;
const DATA_RANGE = "Sheet1!A1:K2";
export const DATE = 0; //     A
export const TYPE = 1; //     B
export const VALUE = 2; //    C
export const DURATION = 3; // D
export const FLAG = 4; //     E
export const NOTES = 5; //    F
export const ID = 6; //       G
export const USERNAME = 7; // H
export const SKIP = "SKIP";
import { padNum } from "./DateHelpers";

export const HAS_DURATION = ["sleep", "feeding", "fussyness"];

/**
 * This function returns the headers needed for axios calls to the Google spreadsheet.
 *
 * @param {string} token - Google API token
 * @returns {object} header with access token
 */
function getHeader(token) {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
}

/**
 * Tests whether the item is empty or not
 *
 * @param {string|number|array} val
 *
 * @returns {boolean}
 */
export function isEmpty(val) {
  if (
    val == undefined ||
    val == 0 ||
    val == -1 ||
    val == "" ||
    (Array.isArray(val) && val.length == 0)
  ) {
    return true;
  }
  return false;
}

/**
 * Gets the user information from the Google api
 *
 * @param {string} token
 *
 * @returns  {object} - the remaining data
 */
export async function getUserInfo(token) {
  let url = "https://www.googleapis.com/oauth2/v3/userinfo";
  const response = await axios.get(url, getHeader(token));
  return response.data;
}

/**
 * Inserts a row in the google sheets
 *
 * @param {string} token
 * @param {number} at - the position to insert the row at
 *
 * @returns {boolean} - whether the operation was successful
 */
async function insertRow(token, at = 1) {
  let url = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}:batchUpdate`;

  let postData = {
    requests: [
      {
        insertDimension: {
          range: {
            sheetId: 0,
            dimension: "ROWS",
            startIndex: at,
            endIndex: at + 1
          },
          inheritFromBefore: false
        }
      }
    ]
  };
  const response = await axios.post(url, postData, getHeader(token));
  return true;
}

/**
 * This is the function that will handle converting the JSON object
 * from the form into a row in Google
 *
 * @param {object} rawData
 * @param {string} token - Google API token
 * @param {string} username - the user name
 * @returns {boolean} success/failure
 */
export async function submitForm(rawData, token, username) {
  // deep clone the data from the form in case we need to modify it in anyway
  let formData = JSON.parse(JSON.stringify(rawData));
  // remove the 'slot'
  delete formData.slots;
  //   await new Promise((r) => setTimeout(r, 1000));

  if (formData.type == "weight") {
    formData.value =
      parseFloat(formData.pounds || 0) +
      parseFloat(formData.ounces || 0) / 16.0;
  }

  if (formData.type == "height") {
    formData.value =
      parseFloat(formData.feet || 0) + parseFloat(formData.inches || 0) / 12.0;
  }

  if (formData.value == undefined || formData.value == "undefined") {
    formData.value = "";
  }
  await insertRow(token);

  // constructs the values array
  let values = new Array(8);
  values[DATE] = formData.datetime;
  values[TYPE] = formData.type;
  values[VALUE] = new String(formData.value);
  values[DURATION] = formData.duration;
  values[FLAG] = formData.flag;
  values[NOTES] = formData.notes;
  values[ID] = formData.id;
  values[USERNAME] = formData.username || username;
  // add the row
  let url = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${DATA_RANGE}:append?valueInputOption=USER_ENTERED`;
  let postData = {
    range: DATA_RANGE,
    majorDimension: "ROWS",
    values: [values]
  };

  const response = await axios.post(url, postData, getHeader(token));
  return true;
}

/**
 * Updates a row with the new data
 * @param {string} token - the token
 * @param {Object} rowData - the row data
 * @param {number} duration - the duration
 * @returns {boolean} - whehter there was success or not
 */
export async function updateRow(token, rowData, columnNum = DURATION) {
  console.log("updateFormRow", rowData);
  let start = 1;
  let end = start + 100;
  let url = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/Sheet1!A${start}:H${end}`;
  const response = await axios.get(url, getHeader(token));

  let rows = response.data.values;
  for (let rowNum in rows) {
    if (rows[rowNum][ID] == rowData.id) {
      start += parseInt(rowNum);
      break;
    }
  }
  if (start == 1) {
    console.error("no matching row was found");
    throw new Error("no matching errors");
  }

  if (columnNum != DURATION) {
    throw new Error("not supported for columns other than duration");
  }

  let value = rowData.duration;
  if (isEmpty(rowData.duration)) {
    value = SKIP;
  }

  url = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/Sheet1!D${start}:D${start}?valueInputOption=USER_ENTERED`;
  let postData = {
    majorDimension: "ROWS",
    values: [[value]]
  };
  const response2 = await axios.put(url, postData, getHeader(token));
  return true;
}

// /**
//  * This is the function that will handle reading a JSON object from the Google Sheet
//  *
//  * @param {string} token - Google API token
//  * @returns {Array} data values from the google sheet
//  */
// export async function getIncompleteRows(token) {
//   let start = 2;
//   let end = start + 100;
//   let url = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/Sheet1!A${start}:H${end}`;
//   const response = await axios.get(url, getHeader(token));
//   const data = response.data.values;
//   let needsEnd = [];
//   for (let row of data) {
//     let rowObj = convertRowToObject(row);
//     if (Object.keys(rowObj).length == 0) {
//       continue;
//     }
//     if (
//       HAS_DURATION.includes(rowObj.type) &&
//       (rowObj.duration == undefined || rowObj.duration == "")
//     ) {
//       needsEnd.push(rowObj);
//     }
//   }
//   return needsEnd;
// }

/**
 * This is the function that will handle reading a JSON object from the Google Sheet
 *
 * @param {string} token - Google API token
 * @returns {object} data values from the google sheet
 */
export async function getFormData(token, start = 2, max = 998) {
  let end = start + max;
  if (start != 2) {
    end = start + max + 1;
  }
  let url = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/Sheet1!A${start}:H${end}`;

  const response = await axios.get(url, getHeader(token));
  const data = response.data.values;
  let results = [];
  for (let value of data) {
    let type = value[1];
    let obj = convertRowToObject(value);
    if (Object.keys(obj).length == 0) {
      continue;
    }
    results.push(obj);
  }
  console.log("getFormData", results);
  return results;
}

/**
 * converts a row of data into an object
 *
 * @param {array} row
 * @returns  {object}
 */
function convertRowToObject(row) {
  if (row.length == 0) {
    return {};
  }
  let dateVal = new Date(
    row[DATE].replace(" ", "T").replace(/T(\d):/, "T0$1:")
  );
  return {
    id: row[ID],
    type: row[TYPE],
    datetime: dateVal,
    day: `${dateVal.getFullYear()}-${padNum(dateVal.getMonth() + 1)}-${padNum(
      dateVal.getDate()
    )}`,
    value: toRaw(row[VALUE], true),
    duration: toRaw(row[DURATION]),
    flag: row[FLAG] === "TRUE" || row[4] === "true",
    notes: row[NOTES],
    username: row[USERNAME]
  };
}

/**
 * Converts a data bit from GoogleSheets back into the JS primitive
 *
 * @param {string|number} val  - the value
 * @param {boolean} isArray - whehter the data is from an array
 *
 * @returns {string|number|array}
 */
function toRaw(val, isArray = false) {
  if (isEmpty(val)) {
    return undefined;
  }
  if (isNaN(parseFloat(val)) == false) {
    return parseFloat(val);
  }
  if (isArray) {
    return val.split(",");
  }
  return val;
}
