import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import { plugin, defaultConfig as viteConfig } from "@formkit/vue";

import vue3GoogleLogin from "vue3-google-login";

import "./assets/main.css";

const CLIENT_ID = import.meta.env.VITE_CLIENT_ID;

const app = createApp(App);

app.use(vue3GoogleLogin, {
  clientId: CLIENT_ID,
});

app.use(
  plugin,
  viteConfig({
    theme: "genesis", // will load from CDN and inject into document head
  })
);
app.use(createPinia());
app.use(router);

app.mount("#app");
