/**
 * returns dates between the two dates
 *
 * @param {date} start - start date
 * @param {date} end  - end date
 * @returns {array}
 */
export function getDatesBetween(start, end) {
  let arr = [];
  let ds = roundToDay(start);
  let de = roundToDay(end);
  while (ds <= de) {
    arr.push(ds.toISOString().slice(0, 10));
    ds.setDate(ds.getDate() + 1);
  }
  return arr;
}

/**
 * Rounds date to the day
 *
 * @param {Date} date
 *
 * @returns {Date}
 */
export function roundToDay(date) {
  let d = new Date(date);
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
}

/**
 * pads a number with leading zeros (up to 2)
 *
 * @param {number} num - the low-digit int
 * @returns {string}
 */
export function padNum(num) {
  return new String(num).padStart(2, "0");
}

/**
 * reformats the date into a valid format for the browser
 *
 * @param {Date} date - optionally a date to format
 *
 * @returns {String}
 */
export function getCurrentDate(date = new Date()) {
  return `${date.getFullYear()}-${padNum(date.getMonth() + 1)}-${padNum(
    date.getDate()
  )}T${padNum(date.getHours())}:${padNum(date.getMinutes())}`;
}

/**
 * Format the time part of a date
 *
 * @param {string|date} date
 * @param {boolean} includeDayOfWeek
 *
 * @returns {string}
 */
export function formatTime(date, includeDayOfWeek = false) {
  if (typeof date === "string") {
    date = new Date(date);
  }
  if (date == undefined || date.getHours == undefined) {
    return "";
  }
  const lookup = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];
  let ampm = "AM";
  let hours = date.getHours();
  hours = hours % 12;
  if (date.getHours() >= 12) {
    ampm = "PM";
  }
  if (hours == 0) {
    hours = 12;
  }
  let dayOfWeek = "";
  if (includeDayOfWeek) {
    dayOfWeek = `${lookup[date.getDay()]} `;
  }
  return `${dayOfWeek}${padNum(hours)}:${padNum(date.getMinutes())} ${ampm}`;
}

/**
 * Sorts the data by day reversed
 *
 * @param {date} start
 * @param {date} end
 *
 * @returns {Number}
 */
export function sortReversed(start, end) {
  if (start.day < end.day) return -1;
  if (start.day > end.day) return 1;
  return 0;
}
