<script setup>
import { RouterLink, RouterView } from "vue-router";
import { useSheetsData } from "./stores/sheetsData";
import { ref, watch, provide } from "vue";
import { googleSdkLoaded, googleLogout } from "vue3-google-login";
import { getUserInfo } from "./helpers/FormHelper";
const yourLogoutFunction = () => {
  // your logout logics
  googleLogout();
};

let token = ref("");
let intervalCode = ref("");
let client = undefined;
let username = ref("");
const error = ref(false);
const errorMessage = ref("");
const errorException = ref("");
const expires = ref(undefined);
const closeError = () => {
  error.value = false;
  errorMessage.value = "";
  errorException.value = "";
};
const store = useSheetsData();
const logError = (msg, exception) => {
  error.value = true;
  errorMessage.value = msg;
  errorException.value = exception;
};

watch(token, async (current, _old) => {
  if (current != undefined && current != "") {
    let data = await getUserInfo(current);
    username.value = data.given_name;
    refreshStore(1000);
  }
});
provide("token", token);
provide("username", username);

const login = async () => {
  googleSdkLoaded((google) => {
    client = google.accounts.oauth2.initTokenClient({
      client_id:
        "366460494015-sevlnqkc5dcpd9je0fi8cnp252c20j1s.apps.googleusercontent.com",
      prompt: "",

      scope:
        "email \
        profile \
        openid \
        https://www.googleapis.com/auth/spreadsheets",
      callback: (response) => {
        // set the token
        token.value = response.access_token;
        //clear the interval
        if (intervalCode.value != "") {
          clearInterval(intervalCode.value);
        }

        //auto-refresh the token
        intervalCode = setInterval(() => {
          requestAnimationFrame(() => {
            console.log("setIntervalCalled to-re-request accessToken");
            client.requestAccessToken();
          });
        }, parseInt(response.expires_in) * 1000 - 5000);
      },
    });

    refreshToken();
  });
};

const refreshStore = async (max = 100) => {
  store.refreshData(token.value, max);
};

const refreshToken = () => {
  if (isLoggedIn() == true) {
    return;
  }
  // need to do a "setInterval to renew the token and/or test whether you need to login; if there's a valid token, we can disable/hide the login button"
  try {
    client.requestAccessToken();
  } catch (e) {
    logError(e.message, e);
  }
};

const isLoggedIn = () => {
  if (token.value != undefined && token.value != "" && username.value != "") {
    return true;
  }
  return false;
};

// document.addEventListener("visibilitychange", () => {
//     if (document.visibilityState === "visible") {
//         // page is visible
//     } else {
//         // page is hidden
//     }
// });
</script>

<template>
  <header>
    <div class="wrapper" v-if="isLoggedIn() == true">
      <nav>
        <RouterLink :to="{ name: 'home' }">Home</RouterLink> |
        <RouterLink :to="{ name: 'overview' }">Overview</RouterLink> |
        <RouterLink :to="{ name: 'visualization' }">Data</RouterLink> |
        <a href="#" @click="yourLogoutFunction">Logout</a>
      </nav>
    </div>
  </header>
  <form v-if="isLoggedIn() == false" class="formkit-form loginForm">
    <div class="formkit-actions">
      <div class="formkit-outer" data-family="button" data-type="submit">
        <div class="formkit-wrapper">
          <button @click="login" class="formkit-input" type="button">
            Login Using Google
          </button>
        </div>
      </div>
    </div>
  </form>
  <div class="error" v-if="error">
    {{ errorMessage }}<br />
    {{ errorException }}<br />
    <button type="button" @click="closeError">ok</button>
  </div>
  <div v-else-if="isLoggedIn() == true">
    <RouterView @error="logError" @refreshStore="refreshStore" />
  </div>
</template>

<style scoped>
header {
  line-height: 1.5;
  max-height: 100vh;
  display: flex;
  justify-content: center;
}

.loginForm {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 80%;
}
nav {
  display: flex;
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 1rem;
  padding-bottom: 1rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
}

nav a:first-of-type {
  border: 0;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    justify-content: center;
    /* place-items: center; */
    /* padding-right: calc(var(--section-gap) / 2); */
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  nav {
    text-align: left;
    margin-left: -1rem;
    font-size: 1rem;

    padding: 1rem 0;
    margin-top: 1rem;
  }
}
</style>
