import { defineStore } from "pinia";
import { HAS_DURATION, SKIP, getFormData } from "@/helpers/FormHelper";

export const useSheetsData = defineStore("sheetsData", {
  state: () => ({
    /** @type {{ text: string, id: number, isFinished: boolean }[]} */
    data: []
  }),
  getters: {
    allData(state) {
      return state.data
        .filter((row) => row.duration != SKIP)
        .sort((a, b) => {
          if (a.datetime > b.datetime) return 1;
          if (b.datetime > a.datetime) return -1;
          return 0;
        });
    },
    dataByType:
      (state) =>
      (howLong = undefined) => {
        let types = {};
        state.data
          .filter((row) => row.duration != SKIP)
          .sort((a, b) => {
            if (a.datetime > b.datetime) return 1;
            if (b.datetime > a.datetime) return -1;
            return 0;
          })
          .forEach((row) => {
            if (
              (howLong != undefined && row == undefined) ||
              row.datetime == undefined ||
              row.datetime < howLong
            ) {
              return;
            }

            if (types[row.type] == undefined) {
              types[row.type] = [];
            }
            types[row.type].unshift(row);
          });
        return types;
      },
    incompleteItems(state) {
      return state.data.filter(
        (rowObj) =>
          HAS_DURATION.includes(rowObj.type) &&
          (rowObj.duration == undefined || rowObj.duration == "")
      );
    },
    latestItems(state) {
      let toReturn = {};
      state.data.forEach((entry) => {
        if (toReturn[entry.type] == undefined) {
          toReturn[entry.type] = { datetime: new Date(0) };
        }
        if (
          toReturn[entry.type].datetime < entry.datetime &&
          entry.duration != SKIP
        ) {
          if (
            HAS_DURATION.includes(entry.type) &&
            (entry.duration == undefined || entry.duration == "")
          ) {
            return;
          }
          toReturn[entry.type] = entry;
        }
      });
      return toReturn;
    }
  },
  actions: {
    async refreshData(token, size = 10) {
      if (token.value != undefined) {
        token = token.value;
      }
      const formData = await getFormData(token, 2, size);
      formData.forEach((row) => this.addData(row));
    },
    // any amount of arguments, return a promise or not
    addData(data) {
      // you can directly mutate the state
      let index = this.data.findIndex((entry) => data.id == entry.id);
      if (index > -1) {
        this.data[index] = data;
      } else {
        this.data.unshift(data);
      }
    }
  }
});
